html,
.card-container{
    width:100%;
    height:100%;
    margin:0;
    padding:0;
}

#all{
    width:100%;
    height:100%;
    overflow-x:hidden;
    overflow-y: visible;
    position:relative;
}

.card-container{
    font-family: Helvetica, Arial, sans-serif;
    font-size:12pt;
    /*background:#444;*/
    color:#352e2a;
    /*background: url('http://www.claudiogomboli.com/lab/gallery/imgs/background.jpg');*/
    background-size: cover;
    background-position: center center;
}

.card-link:link, .card-link:visited {
    color: #333;
    border-bottom:1px solid #333;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    text-decoration:none;
}

.card-link:hover {
    color: #000;
    border-bottom:1px solid #000;
    text-decoration:none;
}

.portfolio img{
    width:100%;
}

#allcontent{
    margin:60px auto 0 auto;
    width:100%;
    max-width:500px;
    height:700px;
    position:relative;
    -webkit-animation: comein 1.5s ease-in-out;
    -moz-animation: comein 1.5s ease-in-out;
    animation: comein 1.5s ease-in-out;
}

.portfolio{
    width:100%;
    /*max-width:1000px;*/
    position:absolute;
    right:0;
    top:0;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: .2s;
    cursor:pointer;
    -webkit-box-shadow:-2px 0 3px rgba(0,0,0,0.3);
    -moz-box-shadow:-2px 0 3px rgba(0,0,0,0.3);
    box-shadow:-2px 0 3px rgba(0,0,0,0.3);
}


.portfolio:nth-child(1) {
    left: 10px;
}
.portfolio:nth-child(1):hover {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-2deg);
    left: 0;
}

.portfolio:nth-child(2) {
    left: 10%;
}
.portfolio:nth-child(2):hover {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-2deg);
    left: 5%;
}

.portfolio:nth-child(3) {
    left: 20%;
}
.portfolio:nth-child(3):hover {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-2deg);
    left: 15%;
}

.portfolio:nth-child(4) {
    left: 30%;
}
.portfolio:nth-child(4):hover {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-2deg);
    left: 25%;
}

.portfolio:nth-child(5) {
    left: 40%;
}
.portfolio:nth-child(5):hover {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-2deg);
    left: 35%;
}

.opened {
    z-index: 1000;
    left:0 !important;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow:0 0 3px rgba(0,0,0,0.3);
    -moz-box-shadow:0 0 3px rgba(0,0,0,0.3);
    box-shadow:0 0 3px rgba(0,0,0,0.3);
    width:100%;
    max-width:100vw;
}

.opened img{
    z-index:5;
}

.ombra{
    position:absolute;
    bottom:20px;
    left:10px;
    width:90%;
    height:20px;
    -webkit-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
    -moz-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
    box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
    -webkit-transform:rotate(-2deg);
    -moz-transform:rotate(-2deg);
    transform:rotate(-2deg);
    display:none;
    z-index:-1;
}

.ombra:after{
    display:block;
    content:"";
    position:absolute;
    bottom:-8px;
    right:-93px;
    width:90%;
    height:20px;
    -webkit-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
    -moz-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
    box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
    -webkit-transform:rotate(4deg);
    -moz-transform:rotate(4deg);
    transform:rotate(4deg);
}

.txt{
    display:block;
    margin:-2px 0 0 0;
    padding-top:20px;
    width:98%;
    padding-left:2%;
    height:33px;
    background: #f7f7f7;
}


#navi{
    position:absolute;
    bottom:-40px;
    left:50%;
    margin:0 0 0 -62px;
    background:#000;
    opacity:0.8;
    color:#fff;
    height:24px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    border-radius:20px;
    padding:7px 10px 0 10px;
}

.circle{
    display:inline-block;
    width:15px;
    height:15px;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
    background:#efefef;
    border:1px solid #000;
    margin-right:6px;
    cursor:pointer;
}

.circle:hover{
    background:#fff;
    border:1px solid #ccc;
}

.circle:active,
.activenav,
.activenav:hover{
    background:#666;
    border:1px solid #333;
}

.activenav{
    cursor: default;
}

.circle:last-child{
    margin-right: 0;
}



/* loading */
#loading{
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    top:0;
    left:0;
    z-index:10001;
    position:absolute;
}

.loader{
    display: block;
    height: 30px;
    left: 50%;
    margin: -15px 0 0 -15px;
    position: absolute;
    top: 50%;
    width: 30px;
    animation: spin 1s infinite linear;
    -moz-animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
}


.footer{
    font-size:0.6em;
    color:#333;
    text-align:center;
    width:100%;
    height:30px;
    position:absolute;
    bottom:-100px;
    left:0;
}


/*enter*/
@-webkit-keyframes comein {
    0% { opacity: 0; -webkit-transform: translateY(-3000px); }
    80% { opacity: 1; -webkit-transform: translateY(40px); }
    100% { -webkit-transform: translateY(0); }
}
@-moz-keyframes comein {
    0% { opacity: 0; -moz-transform: translateY(-3000px); }
    80% { opacity: 1; -moz-transform: translateY(40px); }
    100% { -moz-transform: translateY(0); }
}
@keyframes comein {
    0% { opacity: 0; transform: translateY(-3000px); }
    80% { opacity: 1; transform: translateY(40px); }
    100% { transform: translateY(0); }
}
.MuiStepIcon-root.MuiStepIcon-active {
    color: white !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: green !important;
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: black !important;
}


.MuiInputBase-input {
    color: white !important;
}

.MuiFormLabel-root.Mui-focused {
    color: black !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase {
    color: black !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #60c8f3 !important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid #60c8f3 !important;
}
.MuiInput-underline:before {
    border-bottom: 1px solid rgba(255,255,255,0.5) !important;
}
#country-label {
    padding-top:8px;
}

.MuiFormHelperText-root.Mui-error, .MuiFormLabel-root.Mui-error, .MuiFormLabel-asterisk.Mui-error {
    color: red !important;
}
#message, #message-label, #subject,  #subject-label, #number, #number-label, #email, #email-label, #fullName, #fullName-label,
#username, #username-label, #password, #password-label{
    color: white !important;
}
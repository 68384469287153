/**,*/
/**::before,*/
/**::after {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    box-sizing: border-box;*/
/*}*/

/*:root {*/
/*    --full-width: 100%;*/
/*    --max-width: 62.5rem;*/
/*    --min-width: 22.5rem;*/
/*    --flex-flow: 30rem;*/
/*    --space: 1rem;*/
/*    --conditional-space: clamp(0px, (30rem - 100%) * 999, 1rem);*/
/*}*/

/*.cont {*/
/*    width: clamp(var(--flex-flow), 95%, var(--max-width));*/
/*    padding-block: var(--space);*/
/*    min-width: var(--min-width);*/
/*    !*margin-top:120px;*!*/
/*    !*position: absolute;*!*/
/*}*/

/*.container__flex-flow {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    gap: var(--space);*/
/*    margin-inline: var(--conditional-space);*/
/*}*/

/*.container__flex-flow > * {*/
/*    flex-grow: 1;*/
/*    flex-basis: calc((var(--flex-flow) - var(--full-width)) * 999);*/
/*}*/

/*.card {*/
/*    display: flex;*/
/*    min-width: 12rem;*/
/*    overflow: hidden;*/
/*    border-radius: clamp(0px, (40vw - var(--full-width)), var(--space));*/
/*    box-shadow: rgba(40, 40, 40, 0.1) 0px 2px 3px, rgba(20, 20, 20, 0.2) 0px 5px 8px,*/
/*    rgba(0, 0, 0, 0.25) 0px 10px 12px;*/
/*}*/

/*.card > img {*/
/*    display: block;*/
/*    object-fit: cover;*/
/*    width: 100%;*/
/*    !* aspect-ratio: 16 / 9; *!*/
/*    transition: transform 700ms ease;*/
/*}*/

/*.card:hover img {*/
/*    transform: scale(1.2);*/
/*}*/

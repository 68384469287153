.triangle-left {
    /*width: 0;*/
    /*height: 0;*/
    /*border-top: 700px solid transparent;*/
    /*border-bottom: 700px solid transparent;*/
    /*border-right: 700px solid transparent;*/
    /*margin-top: -700px;*/
    /*margin-left: calc(33vw - 327px);*/
    width: 100%;
    height: 100%;
}

.inner-triangle {
    /*position: relative;*/
    /*top: -700px;*/
    /*left: 2px;*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-top: 695px solid transparent;*/
    /*border-bottom: 695px solid transparent;*/
    /*border-right: 695px solid rgba(0,0,255, 0.7);*/
    width: 300px;
    height: 100%;
    color:white;
}

.mmiddle {
    position: absolute;
    margin-top: 20%;
    /*top: 50%;*/
    display: flex;
    margin-left: 50px;
    /*margin-left: 300px;*/
    transform: translateY(-50%);
    width: 50%;
    text-align: center;
}
.grid-container {
    width: 150px;
}

.mbtn {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #f1f1f1;
    margin: 10px;
    border-radius: 30%;
    box-shadow: 0 5px 15px -5px black;
    color: rgba(0,0,255, 0.7);
    overflow: hidden;
    position: relative;
}

.mbtn svg {
    margin-top: calc(50% - 13px);
    line-height: 90px;
    font-size: 26px;
    transition: 0.2s linear;
}

.mbtn:hover svg {
    transform: scale(1.3);
    color: #f1f1f1;
}

.mbtn::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: blue;
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}

.mbtn:hover::before {
    animation: aaa 0.7s 1;
    top: -10%;
    left: -10%;
}

@keyframes aaa {
    0% {
        left: -110%;
        top: 90%;
    }
    50% {
        left: 10%;
        top: -30%;
    }
    100% {
        top: -10%;
        left: -10%;
    }
}
.graph-container {
    position: relative;
    margin-top: 80%;
    text-align: center;
    justify-content: center;
    display: flex;
}
.home-graph {
    position: absolute;
}
.home-graph-text {
    position: absolute;
    margin-top: 40px;
}

/*************** PHONE **************/
.phone-home{
    width: 100%;
    height: 100%;
}
.half-phone{
    width: 100%;
}
#phone-div {
    width: 300px;
    position: absolute;
    bottom: 0;
    justify-content: center;
    display: flex;
    height: 75%;
    margin: 0px;
    padding: 0px;
}

.app-table {
    width: 300px;
    position: absolute;
    display: table-row;
    top:53%;
    display: flex;
    justify-content: center;
}
.app-table-cell {
    padding:2px;
    display: table-cell;
}
.app-img{
    height:15px;
}

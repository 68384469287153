@import url('https://fonts.googleapis.com/css?family=Shrikhand&display=swap');

@mixin grad-back(){
    background: rgb(255,255,255);
    background: linear-gradient(221deg, rgba(255,255,255,1) 0%, rgba(168,168,162,1) 100%);
}


//*{
//    box-sizing: border-box;
//}

.phone-container{
    display: flex;
    width: 100%;
    height: 450px;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
    transform-style: preserve-3d;
    flex-direction: column;
}

.phone:hover{
    transform: none;
    transform: scale(1.2) rotateY(-25deg);
}
.phone{
    transition: transform 1s;
    position: relative;
    height: 350px;
    width: 200px;
    transform: translateY(-50px) translateX(0px) rotateY(10deg) rotateX(45deg) rotateZ(-20deg);
    transform-style: preserve-3d;
    transform-origin: center center;
    box-shadow: -75px 75px 100px 0px rgba(0, 0, 0, 0.267);
    border-radius: 5px;
    cursor: pointer;

div{
@include grad-back();
}
.front{
    width: 200px;
    height: 350px;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translateZ(25px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.178), inset 0px 0px 2px 0px rgba(0, 0, 0, 0.144);


.screen{
    height: 340px;
    width: 190px;
    background: #000;
    //background: url("https://media.giphy.com/media/STfD4jk0XDJdwxprmF/giphy.gif");
    background-size: cover;
    border-radius: 3px;
    box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.63);

}
}
.back{
    width: 200px;
    height: 350px;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    left: 0px;
}
.left{
    width: 25px;
    height: 342px;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0px;
    left: -12.5px;
    transform: rotateY(90deg) translateX(-12.5px) translateY(4px);
    transform-origin: center center;
.buttons{
    margin-top: 50px;
    background: none;
.button{
    height: 25px;
    width: 10px;
    border-radius: 7px;
    background: #FFFFFF90;
    margin-top: 5px;
    box-shadow: 0px 2px 2px 0px #00000050, inset 0px 0px 3px 0px #00000050;
}
}

}
.right{
    width: 25px;
    height: 342px;

    position: absolute;
    top: 0px;
    left: -12.5px;
    transform: rotateY(90deg) translateX(-12.5px) translateZ(200px) TranslateY(4px);
    transform-origin: center center;


}
.top{
    width: 192px;
    height: 25px;
    position: absolute;
    top: 0px;
    left: 0px;

    transform: rotateX(90deg) translateZ(12.5px) translateY(12.5px) translateX(4px);
}

.bottom{
    width: 192px;
    height: 25px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: rotateX(90deg) translateZ(-337.5px) translateY(12.5px) translateX(4px);

.hole{
    height: 5px;
    width: 5px;
    background: rgba(0, 0, 0, 0.219);
    border-radius: 50%;
    margin: 0px 3px;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.158);
}
.port{
    height: 8px;
    width: 20px;
    background: rgba(0, 0, 0, 0.219);
    border-radius: 10px;
    margin: 0px 8px;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.151);
}

}

}
//html,body{
//  margin: 0;
//  padding: 0;
//  background-color: lighten(#3D6799, 10%);
//}

.wrapper{
  //position: relative;
  //height: 100%;
  //height:300px;
  width: 250px;
  //background-color: green;
  //margin: 100px auto;
  margin-top: 0px;

  .phone_body{
    position: absolute;
    height: 510px;
    width: 250px;
    top: 0; left: 0;
    background-color: #272C32;
    border-radius: 40px 40px 40px 40px;
    box-shadow: 1px 1px 15px -5px black;
    z-index: 5;
    overflow: hidden;
  }

  .screen{
    position: absolute;
    height: 380px;
    width: 206px;
    top: 60px; left: 20px;
    background-color: #1D1C1B;
    background-image: url("../assets/images/len1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 2px solid #1D1C1B;
    transition: background-color .15s ease-out;
    z-index: 6;
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      height: 10px;
      width: 34px;
      background-color: #272C32;
      top: -77px; right: 18px;
      border-radius: 2px 2px 0 0;
    }
    &:before{
      content: '';
      position: absolute;
      height: 650px;
      width: 305px;
      background-color: gray;
      top: -100px; left: -200px;
      opacity: .08;
      transform: rotate(-29.5deg);
    }
  }

  .speaker{
    position: absolute;
    height: 8px;
    width: 44px;
    border: 2px solid #3F464F;
    border-radius: 40px 40px 40px 40px;
    top: 38px; left: calc(125px - 24px);
    background-color: #3F464F;
    z-index: 6;
    &:after{
      content: '';
      position: absolute;
      height: 8px;
      width: 8px;
      border: 2px solid #3F464F;
      border-radius: 50%;
      top: -20px; left: 16px;
      background-color: #3F464F;
    }
  }

  .home{
    position: absolute;
    height: 42px;
    width: 42px;
    border: 2px solid #3F464F;
    border-radius: 40px 40px 40px 40px;
    top: 452px; left: calc(125px - 23px);
    z-index: 6;
    transition: all .1s ease;

    &:hover{
      cursor: pointer;
    }
    &:active{
      background-color: #1D1C1B;
    }
    &:after{
      content: '';
      position: absolute;
      height: 16px;
      width: 16px;
      border: 2px solid #3F464F;
      border-radius: 20%;
      top: 11px; left: 11px;
      opacity: .1;
    }
  }

  .side_buttons{
    position: absolute;
    height: 25px;
    width: 10px;
    background-color: #272C32;
    top: 72px; left: -4px;
    border-radius: 2px 0 0 2px;
    &:before, &:after{
      content: '';
      position: absolute;
      height: 20px;
      width: 10px;
      background-color: #272C32;
      top: 0; left: 0;
      border-radius: 2px 0 0 2px;
    }
    &:before{
      top: 50px;
    }
    &:after{
      top: 90px;
    }
  }

  @keyframes change{
    0%{}
    25%{}
    50%{}
    75%{}
    100%{}
  }

  .active{
    background-color: #C5EFF7;
  }
}
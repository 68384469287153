.title-container {
    min-height: 100%;
    width: 300px;
    height: auto;
    font-size: 2rem;
    letter-spacing: 3px;
    display: flex;
    /*position: absolute;*/
    margin-left: calc(50% - 150px);
}
.name {
    display: flex;
    margin: auto;
    /*padding: 0 1rem 1rem;*/
    position: relative;
}
.name:before {
     content:'';
     width:100%;
     /*opacity:0.3;*/
     bottom:0;
     height: 1px;
     left:0;
     position:absolute;
 }
.letter {
    display:inline-block;
    opacity:0;
    transform:scale(0.9);
    /*animation: text-glow 0.5s ease-in-out infinite alternate;*/
}

blockquote::before {
    content: open-quote;
}

blockquote::after {
    content: close-quote;
}

blockquote::before, blockquote::after {
    opacity: 0.25;
    padding: 0 10px;
    font-size: 3em;
}

blockquote {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 10px;
    /*background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,255,1));*/
}
.MuiCardContent-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/* everything below is for demo appearances and not important to the concept */

.quote-style {
    display: grid;
    color: white;
    place-items: center;
    margin: 0;
    /*background-color: black;*/
    font: 900 1rem/1 'Merriweather', serif;
    font-style: italic;
    text-align: center;
    min-height: 300px !important;
    border: none !important;
}

.quote-style h2 {
    color: black;
}

.test-caro {
}

.wrapper {
    max-width: 660px;
}

@media (max-width: 450px) {
    body { font-size: 1rem; }
}

.slide-container {
    max-width: 800px;
    height: 100%;
}

.comparison {
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
}

figure {
    position: absolute;

    background-repeat: no-repeat;
    background-size: cover;
    font-size: 0;
    width: 150px;
    /*height: 100%;*/
    height: 300px;
    margin: 0;
    /*border-radius: 5px;*/
}

#container-shadow {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 10px;
    border-left-style: inset;
    border-top-style: inset;
    border-right-style: none;
    border-bottom-style: none;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 5px 10px 18px #888888;
    width: 150px;
    height: 300px;
}
#container-trans {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

#divisor {
    /*background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/photoshop-face-after.jpg);*/
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 50%;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.8);
    bottom: 0;
    height: 100%;
}

#divisor::before, #divisor::after {
    content: '';
    position: absolute;
    right: -1px;
    width: 2px;
    height: calc(50% - 25px);
    background: white;
    z-index: 3;
}
#divisor::before {
    top: 0;
    box-shadow: 0 -3px 8px 1px rgba(0, 0, 0, .3);
}
#divisor::after {
    bottom: 0;
    box-shadow: 0 3px 8px 1px rgba(0, 0, 0, .3);
}
#handle {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
}
#handle::before, #handle::after {
    content: '';
    width: 0;
    height: 0;
    border: 6px inset transparent;
    position: absolute;
    top: 50%;
    margin-top: -6px;
}
#handle::before {
    border-right: 6px solid white;
    left: 50%;
    margin-left: -17px;
}
#handle::after {
    border-left: 6px solid white;
    right: 50%;
    margin-right: -17px;
}

input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    /*top: 50%;*/
    top: 150px;
    left: -25px;
    transform: translateY(-50%);
    background-color: transparent;
    width: calc(150px + 50px);
    z-index: 2;
}
input[type=range]:focus, input[type=range]:active {
    border: none;
    outline: none;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid white;
    box-shadow: 0 0 8px 1px rgba(0,0,0,.3);
}
input[type=range]::-moz-range-track {
    -moz-appearance:none;
    height:15px;
    width: 100%;
    background-color: transparent;
    position: relative;
    outline: none;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding:2px !important;
}
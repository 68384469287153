@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
@import url(https://fonts.googleapis.com/css?family=Poiret+One);
@import url(https://fonts.googleapis.com/css?family=Orbitron);
@import url(https://fonts.googleapis.com/css?family=Smooch+Sans);
@import url(https://fonts.googleapis.com/css?family=Saira+Semi+Condensed);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed);
@import url('https://fonts.googleapis.com/css?family=Shrikhand&display=swap');
@import url('https://fonts.googleapis.com/css?family=Kanit:100,700');


html,
body {
  height: 100%;
  min-height:100%;
  min-width: 100% !important;
}
body {
  /*margin-top:88px;*/
  /*background-image: linear-gradient(to right, #2f343d, #4c59f3 );*/
  /*background-image: url("assets/images/portbg.jpg");*/
  /*background-size: 1000px;*/
  /*background-repeat: no-repeat;*/
}
@media screen and (min-width: 900px) {
  body {
    background-size: cover;
  }
}
/*.home-section {*/
/*  background-image: url("./assets/images/webg2_small.png");*/
/*  background-size: auto 100%;*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*  margin-top:0px;*/
/*}*/
@media screen and (min-width: 1000px) {
  /*.home-section {*/
  /*  background-image: none;*/
  /*}*/
}
.banner-image {

  /*-webkit-filter: grayscale(100%); !* Safari 6.0 - 9.0 *!*/
  /*filter: grayscale(100%);*/
  background-image: url("./assets/images/Subject1bw.png");

  background-repeat: no-repeat;
  background-position: bottom;

  background-size: auto 100%;
}
.banner-image-light {

  /*-webkit-filter: grayscale(100%); !* Safari 6.0 - 9.0 *!*/
  /*filter: grayscale(100%);*/
  background-image: url("./assets/images/Subject1bwbright.png");

  background-repeat: no-repeat;
  background-position: bottom;

  background-size: auto 100%;
}
.no-banner-image {
  background-image: none;
}
/* my-theme ********** start *********************************/
/*.body {*/
/*  font-family: 'Poiret One', monospace;*/
/*}*/
h1{
  /*letter-spacing: 1.2px;*/
  /*font-weight: 400;*/
  margin-top: 25px;
}
.my-theme-stepper {
  background-color: transparent !important;
  color:white !important;
}
.my-theme-center-text {
  text-align: center;
  color:blue !important;
}

.new-page-section {
  margin-top: 120px !important;
}
.page-text {
  max-width: 1000px;
}
.no-flexing {
  display: block !important;
}

.formheader {
  /*color: white;*/
  /*font-family: 'Shrikhand', cursive;*/
  text-align: center;

  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  line-height: 1em;
  font-family: 'Kanit', sans-serif;
  color: #d3d3d3;
}
.formsubheader {
  /*font-family: "Saira Semi Condensed";*/
  text-align: center;
  color: white !important;

  font-size: 1.8em;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 100;
  font-family: 'Kanit', sans-serif;
}
.formsubheader-small {
  color: white !important;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 300;
  font-family: 'Kanit', sans-serif;
  text-align: center;
}
.title-container {
  /*!*font-family: "Saira Semi Condensed";*!*/
  /*font-family: 'Shrikhand', cursive;*/
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  line-height: 1em;
  font-family: 'Kanit', sans-serif;
  color: #d3d3d3;

}
.logo-text {
  font-family: "Fira Sans Extra Condensed";
}
.my-theme-font {
  font-family: "Saira Semi Condensed";
}

.my-theme-seconday-text {
  color: white; /*#d3d3d3;*/
}

.my-theme {
  color: rgba(0, 0, 255, 1) !important;
}
.my-theme:hover {
  color: white !important;
}
.my-theme.logo-img {
  /*filter: invert(100%) sepia(10%) saturate(0%) hue-rotate(339deg) brightness(112%) contrast(112%);*/
  height: 100px;
}
@media screen and (min-width: 1000px) {
  .my-theme.logo-img {
    height: 200px;
  }
}
.my-theme.logo-img:hover {
  filter: invert(8%) sepia(100%) saturate(7488%) hue-rotate(248deg) brightness(91%) contrast(144%);
}
.my-theme.navbar-toggler {
  border-color: white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
}
.my-theme.navbar-toggler:focus {
  border-color: blue;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.my-theme.name:before {
  background-color: white;
}
.my-theme.nav-link {
  font-family: 'Saira Semi Condensed';
  text-align: center;
  /*background-color: blue;*/
  background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1) 25%, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
  /*letter-spacing: 1px;*/
  /*animation: text-glow 1s ease-in-out infinite alternate;*/
}
@media screen and (min-width: 1000px) {
  .my-theme.nav-link {
    background-color: transparent;
    background-image: none;
  }
}
@keyframes text-glow {
  from {
    text-shadow: 0 0 1px #fff, 0 0 3px #fff, 0 0 5px blue, 0 0 7px blue, 0 0 10px blue, 0 0 12px blue, 0 0 15px blue;
  }

  to {
    text-shadow: 0 0 1px #fff, 0 0 3px #fff, 0 0 5px darkblue, 0 0 7px darkblue, 0 0 10px darkblue, 0 0 12px darkblue, 0 0 15px darkblue;
  }
}
.no-borderz {
  box-shadow: none !important;
  background-color: transparent !important;
}
.my-theme-color {
  color: white;
}
.footer-button {
  border-color: white;
  color: white;
  width: 40px;
  font-size: small;
}
.MuiTypography-colorTextSecondary {
  color: white !important;
}
/* my-theme ********** end *********************************/
/*************************************ANIMATIONS START******************************/
/*.grow-me {*/
/*  animation:growit 0.6s ease;*/
/*}*/
/*@keyframes growit {*/
/*  0% {transform: scale(1);}*/
/*  100% {transform: scale(1.3);}*/
/*}*/

/*.rotate1{*/
/*  margin:60px auto;*/
/*  transform-style:preserve-3d;*/
/*  animation: rotate 3s forwards;*/
/*}*/
/*.z-index20 {*/
/*  position: absolute;*/
/*  animation: translateZ20 3s forwards;*/
/*}*/
/*.z-index30 {*/
/*  position: absolute;*/
/*  animation: translateZ30 3s forwards;*/
/*}*/

/*@keyframes rotate{*/
/*  from{  transform:rotateY(0);}*/
/*  to{  transform:rotateY(43deg);}*/
/*}*/
/*@keyframes translateZ20{*/
/*  from{transform: translateZ( 0 );}*/
/*  to{transform: translateZ( 110px );}*/
/*}*/
/*@keyframes translateZ30{*/
/*  from{transform: translateZ( 0 );}*/
/*  to{transform: translateZ( 200px );}*/
/*}*/
/*************************************ANIMATIONS END******************************/
.light-blue {
  color: #60c8f3 !important;
}
.reset-button:hover {
  color: blue !important;
}

.phone-hover{
  transform: none !important;
  transform: scale(1.2) rotateY(-25deg) !important;
}

.comment-p {
  text-align: center;
  margin-left: calc(50% - 200px);
  width: 380px;
  min-height: 370px;
  font-style: italic;
}

.embedsocial-hashtag-iframe {
  /*overflow: scroll !important;*/
  /*height: 1000px !important;*/
  /*border: 1px solid black !important;*/
}

.left_text {
  text-align: left;
}
.tick-list {
  width: 15px;
  margin-right: 5px;
  margin-bottom: -2px;
  background-image: radial-gradient(white, blue, rgba(0,0,255, 0));
}
.no-pad {
  padding-inline-start: 0px !important;
  margin-block-start: 0px !important;
  list-style: none;
}
.force-center {
  /*width: 100% !important;*/
  display: flex;
  justify-content: center;
  text-align: center;
}

.animation {
  text-align: center;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 25px;
  letter-spacing: 1px;
  /*color: #fff;*/
  color: white;
  /*display: inline-block;*/
  animation: fadeIn 1.5s forwards;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 590px) {
  .animation {
    font-size: 50px !important;
  }
}

.scrollable {
  overflow: scroll !important;
}

h1 {
  font-size: xx-large;
}
h2 {
  font-size: x-large;
}
h3 {
  font-size: large;
}

.section-video-pad {
  padding-bottom: 45vw;
}

.h800 {
  min-height: 450px !important;
}

.react-player {
  width: 100%;
  height: auto !important;
  /*box-shadow: 0 10px 60px rgba(0, 0, 255,0.5);*/
}

.compare-container{
  margin-top: 10px;
  /*padding: 5px;*/
  height: 500px;
  display: flex;
  margin-left: 0px;
  /*text-align: center;*/
  /*align-self: center;*/
  /*justify-self: center;*/
}

.card {
  border-width: 0px !important;
  min-width: 100vw;
}

.home-text-split {
  color: white;
  position: absolute;
  width: 90%;
  max-width: 500px !important;
  top: 130px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.top-section {
  top: 130px !important;
}

@media screen and (min-width: 1000px) { /*Min size for side by side cards*/
  .home-text-split {
    top: 190px;
  }
  .compare-container{
    margin-left: 10%;
  }
  .top-section {
    top: 270px !important;
  }
  .react-player {
    width: 50%;
  }
  .card {
    min-width: 50%;
  }
  .section-video-pad {
    padding-bottom: 0px;
  }
}

.full-height {
  height: 100% !important;
}

.welcome {
  visibility: hidden;
}

/*.full-height {*/
/*  height: 100%;*/
/*}*/

.col-about .description, .column, .second-title, .resource, .stay-connected-title {
  font-family: 'Source Sans Pro', monospace !important;
}
.col-about .first-title {
  font-family: mylogo;
}

.logo-font {
  font-family: mylogo !important;
  color:white;
}

.btn {
  border-radius: 0 !important;
  border-color: blue;
  color: blue;
}

.copyright {
  font-family: 'Source Sans Pro', monospace;
}

.breadcrumb {
  background-color: rgba(255, 0, 0, 0.0);
}

li.breadcrumb-item a {
  color: indianred;
}

.MuiBadge-colorError {
  background-color: #60c8f3 !important;
  /*background-color:#4c59f3 !important;*/
}
canvas {
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

/*.bg-second {*/
/*  background-color: #6f232f !important;*/
/*  margin-top: -20px;*/
/*}*/
.btn-outline-secondary {
  color: white !important;
}

.footer-container {
  width: 100vw !important;
  /*position: absolute !important;*/
  bottom: 0;
  right: 0;
  left: 0;
  /*position: fixed !important;*/
}
.separate-page {
  position: relative !important;
  padding-top: 150px;
  justify-content: center;
  text-align: center;
  padding-left: 2vw;
  padding-right: 2vw;
  width:100%;
  min-width: 100vw !important;
}

@font-face {
  font-family: mylogo;
}

.glowtext-no {
  font-family: mylogo;
}

.dropdown-menu {
  text-align: center;
  /*display: inline-block;*/
  /*display: table;*/
  /*margin: 0 auto;*/
}
/*.checkout-basket {*/
/*  margin: 0 auto; !* Added *!*/
/*  float: none; !* Added *!*/
/*  margin-bottom: 10px; !* Added *!*/
/*}*/


/***************************************************************/
/* [1] The container */
.img-hover-zoom {
  height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
/***************************************************************/
/* [1] The container */
.img-hover-zoom-prod {
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom-prod img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom-prod:hover img {
  transform: scale(1.5);
}
/***************************************************************/
.car-zoom {
  transition: transform 10s ease;
}
.car-zoom-active {
  transform: scale(1.2);
}
/***************************************************************/

.img-wrapper {
  display: inline-block;
  overflow: hidden;
}

.home-caro-zoom {
  display: inline-block;
  overflow: hidden;
}

.home-caro-img-wrap {
  /*width: 200px; any size*/
  height: 70vh; /*any size*/
  min-width: 100%;
}

.cookie-policy {
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.home-caro-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /*magic*/
}

.center-me {
  text-align: center;
}
.colz3{
  column-count:3;
}
.colz1{
  column-count:1;
}

.card-min-with {
  min-width: 300px;
  width: 100%;
}
.checkz:checked {
  color: indianred !important;
}

.MuiFormLabel-root.Mui-focused {
  color: indianred !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid indianred !important;
}

.second-col {
  color: indianred;
}

.second-bcol {
  /*background-color: #6f232f;*/
  background-color: transparent;
  /*background-color: #2f343d;*/
}

.justify-bottom {
  position:absolute !important;
  bottom: 0;
}

.search_row {
  display: flex;
  flex-wrap: wrap; /* Optional. only if you want the items to wrap */
  justify-content: center; /* For horizontal alignment */
  align-items: center; /* For vertical alignment */
}

.center-content {
  display: table;
  margin: 0 auto;
}

.put-right {
  float:right;
}
.put-left {
  float:left;
}
.put-content-center {
  display: flex;
  justify-content: center;
}

input.custom-focus[type="search"]:focus {
  border-color: indianred;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

span {
  cursor:pointer;
}
.number {
  margin:100px;
}

.minus, .plus{
  width:20px;
  height:20px;
  background:#f2f2f2;
  border-radius:4px;
  padding:8px 5px 8px 5px;
  border:1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.number_inp {
  height: 34px;
  width: 100px;
  text-align: center;
  font-size: 26px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.transform-in {
  transform:scale(2);
  -ms-transform:scale(2); /* IE 9 */
  -moz-transform:scale(2); /* Firefox */
  -webkit-transform:scale(2); /* Safari and Chrome */
  -o-transform:scale(2); /* Opera */
}

.transform {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

/*.prodimg:hover {*/
/*  cursor: zoom-in;*/
/*}*/

.MuiCheckbox-root {
  padding: 0px !important;
}
.MuiSelect-select.MuiSelect-select {
  width: 180px;
}

.form-control-search:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

.MuiButton-text {
  color: white !important;
}
.MuiStepIcon-active > .MuiStepIcon-text {
  fill: black !important;
}
.MuiButton-root.Mui-disabled {
  color: rgba(255,255,255, 0.38) !important;
}
.MuiStepLabel-label {
  color: rgba(255,255,255, 0.5) !important;
}
.MuiStepIcon-root {
  color: rgba(255,255,255, 0.38) !important;
}
.MuiStepConnector-line {
  color: rgba(255,255,255, 0.87) !important;
}

.row_underline{border-bottom:1px solid #ccc;}

.quantity_drop {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.button-margin-bottom {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.button-no-pad {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.btn-stripe {
  margin-left: 20px;
  width: 120px !important;
  border-color: #6772e5;
  color:#6772e5;
  background-color: transparent;
}
.no-marg-left {
  margin-left: 0px !important;
}

.btn-stripe:hover {
  color:#6772e5;
  background-color: white;
  border-color: white;
 }
.btn-stripe .MuiSvgIcon-root {
  color: #6772e5;
}
.expand-in-place {
  position: absolute;
  z-index: 1;
}

.required-field {
  color: indianred;
}

.stop_sliding {
  interval: false,
}

.force-height{
  min-height: 300px;
  max-width: 500px;
  /*margin-left: calc(50% - 250px);*/
}

.thumbnail {
  width: 100px;
  max-width: 100px !important;
  height: 150px;
  max-height: 150px !important;
  padding: 0px !important;
  margin-bottom: 5px;
}

.thumbnail_cart {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 50px;
}

.link_element {
  cursor: pointer;
}

.nospace_element {
  margin: 0px;
  padding:0px;
}

.inline_elements {
  float: left;
}

#home_cards {
  margin-top:15px;
  margin-bottom:15px;
}


.outer_container {
  transform-origin: top left;
  max-height: 0;
}

.container {
  position: relative;
  transform-origin: top left;
}

/*.checkbox-round {*/
/*  width: 1.3em;*/
/*  height: 1.3em;*/
/*  margin-bottom: 5px;*/
/*  background-color: white;*/
/*  border-radius: 50%;*/
/*  vertical-align: middle;*/
/*  border: 1px solid #ddd;*/
/*  appearance: none;*/
/*  -webkit-appearance: none;*/
/*  outline: none;*/
/*  cursor: pointer;*/
/*}*/

/*.checkbox-round:checked {*/
/*  background-color: black;*/
/*}*/

/************************* BLINK ME -> START ************************************/
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
/************************* BLINK ME -> END ************************************/

/************************* BUBBLE -> START ************************************/
.speech-bubble {
  position: relative;
  background: #00aabb;
  border-radius: .4em;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: #00aabb;
  border-right: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-right: -20px;
}

/************************* BUBBLE -> END ************************************/

.no-pads {
  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.greyout {
color: lightgrey !important;
}
/************** THINGS THAT NEED TO SCALE -> START ********************/
.shop-product-card-body {
  min-height: 0px;
}
.wide-cookie {
  display: none;
}
.mobile-cookie {
  display: inherit;
}
.confirm-table {
  font-size: small;
}
.no-results {
  font-size: medium;
  text-align: center;
  width: 100vw;
}
.cart-button {
  padding: 3px !important;
}
.MuiTypography-h6 {
  font-size: small !important;
}
.MuiTypography-body1 {
  font-size: xx-small !important;
}
.MuiTimelineContent-root {
  padding: 0px !important;
  max-width: 200px !important;
}
.MuiTimelineOppositeContent-root {
  padding: 0px !important;
  max-width: 100px !important;
  margin: 0px !important;
}
.order-paper {
  padding: 5px 6px !important;
  max-width: 180px;
  margin: 2px;
}

#home-quote {
  width:190px;
  margin-left: calc(33vw - 95px) !important;
  margin-top: 5px;
  font-size: 1.0em;
  animation: color-change 5s infinite;
}
@keyframes color-change {
  50% { color: white; }
  0% { color: #212529; }

}
.notifier {
  display: flex;
  margin-left: calc(50vw - 50px);
  margin-top: 20px;
  width: 100px;
  z-index: 5;
  position: absolute;
  font-size: small;
  text-align: center;
}

.insta-container {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  max-width: 339px;
  max-height: 450px;
}

.contact-form {
  margin: auto;
  width:100%;
}

.body_all {
  width:100%;
  min-height: 65vh;
}
.dropdown-item.active {
  background-color: indianred !important;
}

#muscle-drop:focus:not(:focus-visible){
  outline: none !important;
}
#muscle-drop:focus{
  outline: none !important;
  box-shadow: none;
}

#muscle-drop {
  font-size: small;
  border: none !important;
  color: black;
}

.show > #muscle-drop {
  background-color: white;
  color:grey;
}
#muscle-drop:hover {
  color: indianred;
  background-color: white;
}

.confirm-table {
  padding-left: 0px;
  padding-right: 0px;
}

.wide-footer-buttons {
  display:none
}
.small-footer-buttons {
  display: inherit;
  margin-top: 10px;
}
.wide-footer-links {
  display:none
}
.small-footer-links {
  display: inherit;
}
.prod-search {
  /*max-width: 72px !important;*/
  display:none;
}
.pop-search-button {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
#popup-search {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.nopop-span{
  display:none;
}
.pop-span {
  display: inherit;
}

.search-wrap-s {
 padding: 0px !important;
  width: 40px !important;
}
.brand-logo{
  padding: 0px !important;
  margin: 0px !important;
}

.basket-wrap-s {
  margin: 0px !important;
}
.search-button {
  border-radius: 0.25rem !important;
}
.break-quoute-small {
  display:inherit;
}
.break-quoute {
  display:none;
}
.row_underline {
  font-size: small;
}
.cart-image {
  max-width: 60px;
  padding-right: 3px !important;
  padding-left: 0px !important;
}
.cart-body {
  padding-left: 0px;
  padding-right: 0px;
  width:100vw;
}
.cart-total {
  font-size: medium;
}
.promo-input {
  width: 70vw;
}
.checkout-button-cart {
  width: 90vw;
}
/*********MEDIUM START**********************/
@media screen and  (min-width: 400px) {
  /*.prod-search {*/
  /*  max-width: 72px !important;*/
  /*  display:inherit;*/
  /*}*/
  /*.search-button {*/
  /*  border-radius: 0.25rem !important;*/
  /*  border-bottom-left-radius: 0px !important;*/
  /*  border-top-left-radius: 0px !important;*/
  /*}*/
  /*.search-wrap-s {*/
  /*  padding: 0px !important;*/
  /*  width: inherit !important;*/
  /*}*/
  /*.break-quoute {*/
  /*  display:inherit;*/
  /*}*/
  /*.break-quoute-small {*/
  /*  display:none;*/
  /*}*/
  /*.nopop-span{*/
  /*  display:inherit;*/
  /*}*/
  /*.pop-span {*/
  /*  display: none;*/
  /*}*/
}
/*********MEDIUM END**********************/

@media screen and (min-width: 690px) {
  h1 {
    font-size: 32pt;
  }
  h3 {
    font-size: 22pt;
  }
  .shop-product-card-body {
    min-height: 180px;
  }
  .wide-cookie {
    display: inherit;
  }
  .mobile-cookie {
    display: none;
  }
  .confirm-table {
    font-size: inherit;
  }
  .cart-button {
    padding: 12px !important;
  }
  .no-results {
    font-size: large;
  }
  .prod-search {
    display:inherit;
    max-width: 400px !important;
  }
  .nopop-span{
    display:inherit;
  }
  .pop-span {
    display: none;
  }
  .search-button {
    border-radius: 0.25rem !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  .search-wrap-s {
    padding: 0px !important;
    width: inherit !important;
  }
  /* On large screens, increase size of muscle_label */
  .break-quoute-small {
    display:none;
  }
  .break-quoute {
    display: inherit;
  }
  .wide-footer-buttons {
    display: inherit;
  }
  .small-footer-buttons {
    display:none
  }
  .wide-footer-links {
    display: inherit;
  }
  .small-footer-links {
    display:none
  }

  .confirm-table {
    padding-left: 100px;
    padding-right: 100px;
  }

  .footer-button {
    margin-top: 30px;
    width: 180px;
    font-size: inherit;
  }

  .body_all {
    min-height: 85vh;
  }

  .MuiTypography-h6 {
    font-size: medium !important;
  }
  .MuiTypography-body1 {
    font-size: small !important;
  }
  .MuiTimelineContent-root {
    font-size: medium !important;
    padding: 6px 16px !important;
    max-width: 100% !important;
  }
  .MuiTimelineOppositeContent-root {
    font-size: medium !important;
    padding: 6px 16px !important;
    margin: 0px !important;
  }
  .order-paper {
    padding: 6px 16px !important;
    max-width: 100% !important;
  }
  #muscle-drop {
    font-size: inherit;
  }

  #home-quote {
    font-size: 1.6em;
    width:480px;
    margin-top: 20px;
    margin-left: calc(30vw - 240px) !important;
  }

  .contact-form {
    width:50%;
  }

  /* On large screens, increase size of insta-container */
  .insta-container {
    max-width: 690px;
    max-height: 790px;
  }

  /* On large screens, increase size of notfier */
  .notifier {
    margin-top: 50px;
    font-size: inherit;
  }
  .row_underline {
    font-size: 16px;
  }
  .cart-image {
    max-width: inherit;
    padding: 0px 15px !important;
  }
  .cart-body {
    padding: 20px;
  }
  .cart-total {
    font-size: 1.25rem;
  }
  .promo-input {
    width: 300px;
  }
  .checkout-button-cart {
    width: 300px;
  }
}
/************** XXXXXXL SCREENS ********************/
@media screen and (min-width: 1400px) {
  .break-quoute {
    display:none;
  }
  #home-quote {
    font-size: 1.6em;
    width:900px;
    margin-top: 20px;
    margin-left: calc(30vw - 450px) !important;
  }
}
/************** THINGS THAT NEED TO SCALE -> END ********************/
li {
  margin:0 0 4px 0;
}

.navbar-collapse.collapse.show {
}

.message {
  color: pink;
}

.exercises {
  position: relative;
  width:100%;
}

.muscle_info {
  padding-left:0;
  margin-left:0;
  z-index: 10;
}

.thumbnail_column {
  -ms-flex: 0 0 230px;
  flex: 0 0 230px;
  background-color: greenyellow;
}

@media (max-width: 690px) {
  .thumbnail_column {
    display: none;
  }
}

.flex-fixed-width-item {
  flex: 0 0 100px;
}

.no-padding {
  padding: 0 !important;
}

accordion {
  width: 100%;
}

.orderinfo-columns-1 {
  column-count: 1;
}
.orderinfo-columns-2 {
  column-count: 2;
}
.space-on-top {
  margin-top: 10px;
}
.space-on-top-more {
  margin-top: 50px;
}

.fill-parent {
  width: 100% !important;;
  height: 100% !important;
}

/***************************************************************/
/* Centered text */
.centered {
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-hori {
  /*position: absolute;*/
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-hori2 {
  /*position: absolute;*/
  left: 50%;
  /*transform: translate(-50%, 0);*/
}
/***************************************************************/
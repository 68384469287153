.meet-container {
    min-width: 100%;
    /*position: absolute;*/
    overflow: auto;
}

.section {
    /*min-height: 500px;*/
    background-color: transparent;
    /*max-height: 1000px;*/

    /*box-shadow: none !important;*/
    /*border: none !important;*/
    /*border-radius: 0 !important;*/
}

.no-room {
    padding: 0px !important;
    margin: 0px !important;
}

.meet {
    background-color: transparent;
    /*word-break: break-all;*/
}
.lean-right {
    margin-right: 0px;
}
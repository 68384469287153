main.acontent{
    /*background: red;*/
    perspective: 1000;
    position: relative;
}
.phone-label {
    background-color: rgba(0,0,0,0.5);
    font-size: xx-small;
    text-transform: uppercase;
    font-weight: 400;
}
.agrid {
    transition: all .4s;
    display: flex;
    transform: rotateX(60deg) rotateZ(-50deg);
    position: absolute;
    top: 25px;
    left: 0;
    width: 500px;
    transform-style: preserve-3d;
}
ul.agrid > li {
    width: 140px;
    height: 220px;
    list-style-type: none;
    margin-right: 20px;
    background-size: 0, cover;
    transform-style: preserve-3d;
}
.next-arrow {
    margin-top:7px;
}
/*ul.agrid > li.one {*/
/*     background-image: linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),*/
/*     url("../assets/images/len1.jpg");*/
/* }*/
/*ul.agrid > li.two {*/
/*     background-image: linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),*/
/*     url("../assets/images/len2.jpg");*/
/* }*/
/*ul.agrid > li.three {*/
/*     background-image:*/
/*             linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),*/
/*             url("../assets/images/len3.jpg");*/
/* }*/
/*ul.agrid > li.four {*/
/*     background-image:*/
/*             linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),*/
/*             url("../assets/images/len4.jpg");*/
/* }*/


.agrid li:before, .grid li:after {
    content: '';
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background: inherit;
}
/*Left Side*/
.agrid li:before {
    transform-origin: left center;
    transform: rotateY(90deg);
    width: 5px;
}
.agrid li:after {
    transform-origin: bottom center;
    transform: rotateX(90deg);
    height: 5px; top: auto; bottom: 0;
    background-position: bottom center;
}
.s {
    position: absolute;
    /*30px smaller from each side*/
    top: 30px; left: 30px; right: 30px; bottom: 30px;
    background: rgba(0, 0, 255, 0.5);
    box-shadow: 0 0 30px 30px rgba(0, 0, 255, 0.5);
    /*Pushing down the shadow to give an elevated feel*/
    transform: translateZ(-50px);
}

.agrid.toggle{
    transform: rotateX(0) 	rotateZ(0);
}

#container-shadow {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 10px;
    border-left-style: inset;
    border-top-style: inset;
    border-right-style: none;
    border-bottom-style: none;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 5px 10px 18px #888888;
    width: 150px;
    height: 300px;
}
#container-trans {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}


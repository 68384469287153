@import url('https://fonts.googleapis.com/css?family=Kanit:100,700');

$font: 'Kanit', sans-serif;

$main: #d3d3d3;
$light: #e5e4e2;
$white: #FFFFFF;
$dark: #a9a9a9;


.intro-container {
  font-family: $font;
  overflow: hidden;

  width: 100%;
  display: flex;
  justify-content: center;
}

.effect1 {
  position: absolute;
  margin: 0;
  top: 40%;
  //font-size: 2.8em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  z-index: 3;
  color: $main;
}

.effect1 .item-letter {
  display: inline-block;
  font-weight: 900;
  line-height: 1em;
}

.text {
  position: absolute;
  font-size: 1.8em;
  text-transform: uppercase;
  letter-spacing: 5px;
  //color: $dark !important;
  font-weight: 100;
  top: 47%;
  z-index: 3;
  opacity: 0;
}

section {
  display: grid;
  grid-template-columns: repeat(10, auto);
}

.item {
  background-color: $light;
  height: 100vh;
  z-index: 0;
}
.intro-img {
  animation-name: expose;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}
@keyframes expose {
  0%   {
    filter: brightness(1);
    filter: contrast(1);
    -webkit-filter: brightness(1);
    -webkit-filter: contrast(1);
  }

  100% {
    filter: brightness(0.2);
    filter: contrast(0.2);
    -webkit-filter: brightness(0.2);
    -webkit-filter: contrast(0.2);
  }
}
/*************** PHONE **************/
//.half-phone{
//  height: 100%;
//}
//#phone-div {
//  position: absolute;
//  bottom: 0;
//  width: 100%;
//  justify-content: center;
//  display: flex;
//  height: 75%;
//}
//
//.app-table {
//  width: 100%;
//  position: absolute;
//  display: table-row;
//  top:53%;
//  display: flex;
//  justify-content: center;
//}
//.app-table-cell {
//  padding:2px;
//  display: table-cell;
//}
//.app-img{
//  height:15px;
//}


.standard-section {
    background-color: transparent !important;
    box-shadow: none !important;
    /*background-color: rgba(255,255,255, 0.1) !important;*/
    /*position: absolute;*/
    /*width: 400px;*/
    /*margin-left: calc(50% - 200px);*/
    /*color: white !important;*/
    /*margin-top: 150px;*/
    /*box-shadow: 0px 2px 1px -1px rgba(0, 0, 255, .20), 0px 1px 1px 0px rgba(0, 0, 255, .14), 0px 1px 3px 0px rgba(0, 0, 255, .12) !important;*/
    /*-webkit-transition: transform 2s ease-in;*/
    /*-moz-transition: transform 2s ease-in;*/
    /*transition: transform 2s ease-in;*/
}

.standard-section:hover {
    /*-moz-transform: scale(1.05);*/
    /*-webkit-transform: scale(1.05);*/
    /*-o-transform: scale(1.05);*/
    /*-ms-transform: scale(1.05);*/
    /*-webkit-transform: scale(1.05);*/
    /*transform: scale(1.05);*/
}

p {
    color: black !important;
}

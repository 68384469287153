.star-body {
    /*overflow: hidden;*/
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    /*z-index: 10;*/
}

.tests {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background-color: green;
    border: 2px solid #ccc;
}
.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
}

.navbar {
    padding: 1rem 1rem;
    height: 100px;
}

.fade-from-bottom {
    /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));*/
    /*background: linear-gradient(to bottom, transparent, black);*/
}

.navbar-light .navbar-brand {
    color: white;
}

.bg-dark {
    background: transparent !important;
}
.bg-dark-scroll {
    background: rgba(0,0,0, 0.5) !important;
}
/*.navbar-dark .navbar-nav .nav-link {*/
/*    color: #6f232f;*/
/*}*/

.logo-img:hover {
    filter: invert(100%) sepia(10%) saturate(0%) hue-rotate(339deg) brightness(112%) contrast(112%)
}

.outer_container {
    width:100%;
}

.container {
    width:100%;
    min-width: 100%; 
    background-size: cover;
}

.navbasket-right{
    display: none;
}
.navbasket-collapse{
    display: inherit;
}

.text-image-container {
    position: relative;
}
.logo-text {
    font-size: 20px;
    color:white;
}
.logo-centered {
    position: absolute;
    top: 51.25%;
    left: 42.25%;
    transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbasket-right{
        display: inherit;
    }

    .navbasket-collapse{
        display: none;
    }
    /*.navbar {
        height: 100%;
        width: calc(25% - 20px);
    }

    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
    }



    .navbar-header {
        float: none;
    }

    .navbar .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0;
    }

    .navbar .container-fluid {
        padding: 0;
        margin: 0;
    }

        .navbar .container-fluid .navbar-brand {
            margin: 0;
        }

    .navbar ul {
        float: none;
    }

    .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }

        .navbar li a {
            padding: 10px 16px;
            border-radius: 4px;
        }

    .navbar a {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }*/
}
